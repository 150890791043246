.Root {
  position: absolute;
  margin: auto;
  top: 25px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  width: 80%;
  background-color: rgb(43, 41, 41);
  opacity: 0.9;
}

.List {
  display: flex;
  justify-content: center;
  gap: 30px;
  border: 1px solid white;
  margin: 0;
  padding: 0;
  border-radius: 4px;
}

.List > span {
  list-style: none;
  color: whitesmoke;
  padding: 20px 10px;
}
