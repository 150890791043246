.Root {
    background-color: rgb(43, 41, 41);
    opacity: 0.9;
    border: 1px solid white;
    position: absolute;
    bottom: 25px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    width: 80%;
    height: 80%;
    color: white;
    border-radius: 4px;

    display: grid;
    grid-template-columns: 30% 40% 30%;
}


.AssetBloc {
    justify-content: center;
    align-self: center;
}

.RightMenu {
    border-right-color: white;
    border-right-width: 1px;
    border-right-style: solid;
    overflow-y: scroll;
}

.LeftMenu {
    border-left-color: white;
    border-left-width: 1px;
    border-left-style: solid;
    overflow-y: scroll;
}

.CenterAsset {

    cursor: pointer;
    fill: white;
    width: 100%;
    height: 100%;
}