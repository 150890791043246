.Root {
    all: unset;
    display: flex;
    align-items: center;
    cursor: pointer;
    border: 1px solid white;
    border-radius: 4px;
    padding: 5px 10px;
    justify-content: center;
    margin: auto;
    margin-top: 20px;
    width: 80%;
    box-shadow: 0px 8px 15px  rgba(19, 24, 22, 0.678);
    gap: 25px;
}

.Root:hover {
    background-color: grey;
    box-shadow: 0px 15px 20px rgba(19, 24, 22, 0.678);
    color: #fff;
    transform: translateY(-3px);
}

.Root > span > svg > path {
    fill: white;
}